import * as React from "react"
import { HeartIcon, LockClosedIcon, ServerIcon, LightningBoltIcon, FolderIcon, CheckCircleIcon} from '@heroicons/react/outline'

const features = [
  {
    name: "Blazing fast",
    description:
      "We feature API nodes globally to provide swift upload speeds from wherever you are. Serving content is also very fast globally with our advanced content delivery network.",
    icon: LightningBoltIcon,
  },
  {
    name: "Built for reliability",
    description:
      "Whenever, you can rely on Iodine. Your content is backed up upon uploading and Iodine's infrastructure has been developed to withstand large attacks and traffic.",
    icon: ServerIcon,
  },
  {
    name: "Security in mind",
    description:
      'We ensure your data is encrypted and kept secure. We only use PII such as IP and email address to verify you within our services, at no point is it ever sold or revealed to the public.',
    icon: LockClosedIcon,
  },
  {
    name: "Humane at heart",
    description:
      'We like to be lenient when it comes to your uploads, but to maintain a safe community for everyone our services include a CSAM filter to find and remove abusive content.',
    icon: HeartIcon,
  },
  {
    name: "All kinds of files",
    description:
      'We support a variety of file types, including but not limited to images, videos, zip files, rar files, office documents, and more supported soon.',
    icon: FolderIcon,
  },
  {
    name: "Simple and convenient",
    description:
      "Screenshot, video, and file uploading all in one place? Sounds great! - No more confusing mess, With Iodine, you'll be sharing your files with simplicity in no time.",
    icon: CheckCircleIcon,
  },
]


const Features = () => (
<div className="py-12 bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="text-base text-indigo-400 font-semibold tracking-wide uppercase">Why use Iodine</p>
          <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-300 sm:text-4xl">
            A free, reliable and secure content host
          </h1>
          <p className="mt-4 max-w-2xl text-xl text-gray-400 lg:mx-auto">
          Unlike some others, using Iodine is completely free, no entry fees or invite codes.
          </p>
        </div>

        <div className="mt-10">
          <dl className="mx-6 md:mx-0 space-y-3 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-6 md:gap-y-8 mt-3 grid md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-1 gap-3">
            {features.map((feature) => (
              <div key={feature.name} className="relative bg-gray-800 p-3 rounded-lg shadow-md transform transition duration-500 hover:scale-105">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-300">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-400">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
  export default Features;