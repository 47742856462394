import * as React from "react"

import Header from "./Header"
import Subheader from "./Subheader"
import Features from "./Features"
import Testimonials from "./Testimonials"
import CTA from "./CTA"
import Footer from "./Footer"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
    <div>
      <body className="flex flex-col min-h-screen bg-gray-800 ">
  <header>
  <Header />
  <Subheader />
  </header>
  <main className="flex-grow">
 <Features />
 <Testimonials />
 <CTA />
  </main>
  <footer>
  <Footer />
  </footer>
</body>
    </div>
    </>
  )
}

export default Layout
