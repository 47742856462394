import * as React from "react"
import TE1 from "../images/te1.webp"
import TE2 from "../images/te2.webp"

const Testimonials = () => (
  <section className="text-gray-600 body-font bg-gray-900">
  <div className="container px-5 py-24 mx-auto">
  <div className="text-center">
          <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-300 sm:text-4xl">
            Don't take our word for it
          </h1>
          <p className="mt-4 max-w-2xl text-xl text-gray-400 lg:mx-auto">
          A large part of Iodine is it's community, see what they're saying about us.
          </p>
        </div>
    <div className="flex flex-wrap m-7">
      <div className="p-4 md:w-1/2 w-full transform transition duration-500 hover:scale-105">
        <div className="h-full bg-gray-800 p-8 rounded">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-gray-300 mb-4" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <p className="leading-relaxed mb-6 text-gray-300">Iodine has the fastest upload times and a super nice and responsive team. They continue to impress their users with new features and are constantly fixing bugs found by the community. The experience is 10/10.</p>
          <div className="inline-flex items-center">
            <img className="rounded-full" width="50" height="50" src={TE1} alt="Testimonial Author 1 Avatar" />
            <span className="flex-grow flex flex-col pl-4">
              <span className="title-font font-medium text-gray-300">siege#4537</span>
            </span>
          </div>
        </div>
      </div>
      <div className="p-4 md:w-1/2 w-full transform transition duration-500 hover:scale-105">
        <div className="h-full bg-gray-800 p-8 rounded">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-gray-300 mb-4" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <p className="leading-relaxed mb-6 text-gray-300">Iodine.gg is amazing. No, really! The project developers are always striving to make the brilliant service they offer better. They provide complete transparency and it is so secure! 100% would recommend.</p>
          <div className="inline-flex items-center">
            <img className="rounded-full" width="50" height="50" src={TE2} alt="Testimonial Author 2 Avatar" />
            <span className="flex-grow flex flex-col pl-4">
              <span className="title-font font-medium text-gray-300">csx#5303</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  );
export default Testimonials;