import * as React from "react"
const CTA = () => (
  <div className="bg-indent-gray text-center lg:text-left">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-300 sm:text-4xl">
          <span className="block">Ready to join us?</span>
          <span className="block text-indigo-400">It's free!</span>
        </h2>
        <div className="mt-8 md:mx-0 lg:mt-0 lg:flex-shrink-0 flex justify-center mx-auto">
          <div className="inline-flex shadow">
            <a
              href="https://dash.iodine.gg/apikeys"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-300 bg-indigo-700 hover:bg-indigo-800 transition duration-200 ease-in-out"
            >
              Get your key
            </a>
          </div>
          <div className="ml-3 inline-flex shadow">
            <a
              href="https://iodine.gg/discord"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-300 bg-gray-800 hover:bg-dc-hover transition duration-200 ease-in-out"
            >
              Join Discord
            </a>
          </div>
        </div>
      </div>
    </div>
  );
  export default CTA;